/* src/styles/EntryPage.css */
.entry-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(26, 70, 90, 0.8);
    z-index: 1000;
    padding: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(79, 78, 78, 0.8);
    width: 400px;
    height: 500px;
    /* position: relative; */
}

.tabs-form {
    display: flex;
    border-bottom: 1px solid #0a40c8;
    margin-bottom: 10px;
    margin-top: 10px;
    
    font-size: 75px;
    color: rgb(192, 161, 161);
}

.tabs-form button {
    flex: 2;
    padding: 10px;
    border: rgb(41, 31, 216);
    background: transparent;
    cursor: pointer;
    color: rgb(3, 26, 46);
    font-weight: bolder;
    font-size: large;
}

.tabs-form button.active {
    border-bottom: 2px solid #067dfb;
}

.form-container {
    padding: 5%;
}

.close-btn {
    /* background: #bad5f0; */
    border: 1px solid #024ef4;
    padding: 10px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}
/* 
.form input {
    border: none;
    background: none;
    text-align: center;
    outline: none;
    padding: 10px;
    margin: 20px;
    color: white;
    height: 60px;
    width: 85%;
    border-radius: 40px;
    transition: 0.2s ease-in;
} */

/* 
form input[type="text"],
form input[type="password"] {
    border: solid 2px #1B9CFC
}

form input[type="submit"] {
    border: solid 2px #55E6C1;
    box-sizing: border-box;
    height: 50px;
    width: 40%;
    cursor: pointer;
} */
/* 

form input[type="text"]:focus,
form input[type="password"]:focus {
    width: 80%;
    border: solid 2px #55E6C1;
}

form input[type="submit"]:hover {
    background: #55E6C1;
    color: #182C61;
} */