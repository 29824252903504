/* General styles for the chat app */
.chat-app {
    display: flex;
}

.sidebar-toggle {
    background-color: #007bff;
    color: rgb(48, 179, 203);
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.chat-history {
    max-height: 60%;
    overflow-y: auto;
}

.chat-history ul {
    list-style: none;
    padding: 0;
}

.chat-history li {
    padding: 5px 0;
}

.chat-container {
    flex: 1;
    margin-left: 240px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 95vh;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
}

.chat-message {
    padding: 5px 0;
}

.chat-input-container {
    display: flex;
}

.chat-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.send-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

.send-button img {
    width: 24px;
    height: 24px;
}

.back-button {
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    width: 100%;
}

.back-button:hover {
    background-color: #1976D2;
}
/* Sidebar styles */
.sidebar {
    /* width: 300px; */
    height: 95%;
    position: fixed;
    top: 1%;
    /* left: -300px; */
    /* Initially hidden */
    transition: left 0.3s ease;
    background-color: #fcf5f5;
    border-color: #007bff;
    border-radius: 1%;
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;
}

.sidebar.open {
width: 200px;
}



.chat-history {
    margin-top: 20px;
}

.chat-history h3 {
    margin-bottom: 10px;
}

.chat-history ul {
    list-style: none;
    padding: 0;
}

.chat-history ul li {
    margin-bottom: 5px;
}