.auth-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.auth-popup {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 400px;
    z-index: 1001;
}

.auth-popup .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.auth-popup h2 {
    margin-bottom: 20px;
    text-align: center;
}
.homepage-container {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    background: linear-gradient(135deg, #0a4151, #033572);
    min-height: 100vh;
    color: #fff;
   
        height: 100%;
        width: 100%;
        overflow: hidden;
    

}