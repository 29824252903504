
form input {
    border: none;
    background: none;
    text-align: center;
    outline: none;
    padding: 10px;
    margin: 20px;
    color: rgb(8, 8, 8);
    height: 50px;
    width: 90%;
    border-radius: 40px;
    /* transition: 0.2s ease-in; */
    font-size: large;font-weight: bold;
    

}

form input[type="text"],
form input[type="password"],
form input[type="email"]{
    border: solid 2px #1B9CFC;
    color: #01040b;
    
    
}

.btn-submit {
    border: solid 2px #55E6C1;
    box-sizing: border-box;
    height: 40px;
    width: 50%;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    margin: 20px;
    border-radius: 40px;
    margin-left: 90px;
    font-size: medium;
        font-weight: bolder;

                    /* transition: 0.2s ease-in; */
}


form input[type="text"]:focus,
form input[type="password"]:focus {
    width: 70%;
    border: solid 2px #55E6C1;
}

form button:hover {
    background: #55E6C1;
    color: #182C61;
   
}
