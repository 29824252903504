.hero {
    text-align: center;
    margin: 40px 0;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

.hero-content h2 {
    font-size: 2.2rem;
    margin-bottom: 10px;
}
@media (max-width: 768px) {


    .hero-content h2 {
        font-size: 1.8rem;
    }


}