.image-converter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.file-input,
.file-type-select {
    margin: 10px;
}

.original-image {
    max-width: 100%;
    max-height: 300px;
    margin: 10px;
}

.loading-message {
    font-size: 16px;
    color: #007bff;
    margin: 10px;
}

.button-container {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.download-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.download-button:hover {
    background-color: #0056b3;
}